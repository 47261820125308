<template>
  <div class="chat-content">
    <dl class="rebot-style">
      <dt>
        <img :src="require(`@/assets/images/chat_image_head_bebot.png`)">
      </dt>
      <dd style="min-width: 5rem;">
        <ul v-if="!showJybInfo" class="recommend content">
          <li
            class="list"
            v-for="(item,index) in recommendList"
            :key="index"
          >
            <p
              class="popularText"
              @click="sendMessage(item,1)"
            >
              {{item.content}}
            </p>
          </li>
          <p
            class="cutover"
            @click="getQuestion"
          >换一换</p>
        </ul>
        <ul v-else class="recommend content remind-content">
          <li v-for="(item, index) in recommendList" :key="index" class="remind-row" @click="sendMessage(item, 2)">
            <span class="text">{{ item.title }}</span>
            <span class="count">{{ item.count }}</span>
            <van-icon name="arrow" class="arrow" />
          </li>
        </ul>
      </dd>
    </dl>
    <div
      v-for="(item, index) in list"
      :key="item.id"
    >
      <dl>
        <dd>
          <!-- item.mode 0普通，1智能核保 &&item.mode!=1 -->
          <!-- item.content && item.content.hebao == 0 zzj添加 -->
          <p
            class="add-chat"
            v-if="item.content && item.content.hebao == 0 && item.speaker==0&&item.is_collected=='0' &&
            underwriting!=1"
            @click="addChatToKnowdge(index)"
          >
            <img :src="require('@/assets/images/common_icon_add.png')" />
            添加对话至收藏夹
          </p>
          <p
            class="go-look"
            v-if="item.speaker==0&&item.is_collected==1"
            @click="goLook(item,index)"
          >
            前往查看
          </p>
        </dd>
      </dl>
      <dl
        v-if="underwriting == 1 && item.content!='核保' || underwriting!=1"
        :class="item.speaker == 0 ? 'rebot-style' : 'custom-style'"
      >
        <dt>
          <img :src="item.speaker==0?require(`@/assets/images/chat_image_head_bebot.png`):userInfo.head_portrait">
        </dt>
        <dd>
          <div
            class="content"
            v-if="underwriting == 1 && item.content!='核保' || underwriting!=1"
          >
            <!-- 左侧回答 -->
            <span
              v-if="item.speaker == 1"
              class="contentText"
              v-html="item.content"
              style="word-break:break-all;"
            ></span>
            <!-- 机器人（核保） -->
            <div
              v-if="item.speaker == 0 && item.content.hebao == 1"
              class="contentText"
              v-html="item.content.answer"
            >
            </div>
            <!-- 鲸云保提醒 -->
            <div v-if="item.speaker == 0 && item.content.hebao == 0 && item.content.event">
              <template v-if="item.content.event === 'article'">
                <div class="article-head">近期热门文章</div>
                <li v-for="(_item, index) in item.content.answer" :key="index" class="info-row">
                  <span class="article-title">{{ _item.title }}</span>
                  <span class="article-time">{{ _item.createTime | format2MMYY }}</span>
                </li>
                <div style="text-align: center;">
                  <span class="go-article__btn" @click="goArticle">感兴趣，去看看</span>
                </div>
              </template>
              <template v-else-if="item.content.event === 'poster'">
                <div v-if="item.content.answer.length === 0">
                  当日暂未上新海报
                </div>
                <template v-else>
                  <li class="img-list">
                    <img v-for="(_item, index) in item.content.answer" :key="index" class="poster-img" :src="_item.imgUrl">
                  </li>
                  <div style="text-align: center;">
                    <span class="go-article__btn" @click="goPosterShare">去分享</span>
                  </div>
                </template>
              </template>
              <template v-else-if="item.content.event === 'backlog'">
                <div v-if="item.content.answer.length === 0" class="to-do__wrap">
                  <!-- 您当日暂无待办事项，快去创建吧~ -->
                  <div>您当日暂无待办事项，快去创建吧~</div>
                  <div @click="goCalendar" class="cld-btn">去创建</div>
                  <!-- <span @click="goCalendar">创建待办事项</span> -->
                  <!-- <van-icon @click="goCalendar" name="plus" /> -->
                </div>
                <template v-else>
                  <div class="to-do__head">待办事项</div>
                  <div>
                    <li v-for="(_item, index) in item.content.answer" :key="index" class="to-do__list">
                      <span class="point"></span>
                      <span class="visit-time">{{ _item.visitTime | format2mmss }}</span>
                      <span class="visit-type">{{ _item.type }}</span>
                      <span class="user-name">{{ _item.username }}</span>
                      <div v-if="index < item.content.answer.length - 1" class="point-list">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                  </div>
                  <div class="btn-group">
                    <span @click="goCalendar" class="more-btn">更多</span>
                    <span @click="goCalendar" class="complete-btn">去完成</span>
                  </div>
                </template>
              </template>
            </div>
            <!-- 机器人普通对话 -->
            <div
              v-if="item.speaker == 0 && item.content.hebao == 0 && !item.content.event"
              class="contentText"
            >
              <p
                :style="index+1==list.length?'padding-bottom:.27rem':'padding-bottom:0'"
                :class="each.canClick?'chatText':'noChatText'"
                v-for="(each,num) in item.content.answer"
                :key="num"
              > 
                <span v-if="each.type=='pdf'||each.type=='PDF'" v-text="each.text" @click="showPdf(each.url)" class="pdf-text"></span>
                <span
                  v-else
                  :style="each.canClick && num+1!=item.content.answer.length?'border-bottom: 1px solid rgba(255, 255, 255, 1);':''"
                  v-show="each"
                  @click="sendMessage(each)"
                  v-html="$options.filters.replaceLabel(each.text)"
                >
                </span>
              </p>
              <p
                class="lines"
                v-show="index+1 == list.length && hideHistoryText!=1"
              ></p>
              <div
                class="assess"
                v-show="index+1 == list.length && hideHistoryText!=1"
              >
                <div
                  v-if="item.content.buy_intention && !item.content.linkman"
                  class="treadNoBox artificial"
                  style="width:2.1rem"
                  @click="openArtificialDialog(item)"
                >人工协助</div>
                <div
                  :class="item.assess == 1?'greatBox':'greatNoBox'"
                  @click="reviews(item,1)"
                >
                  <p :class="item.assess == 1?'greatImg':'greatNoImg'"></p>
                  <p :class="item.assess == 1?'greatText':'greatNoText'">赞</p>
                </div>
                <div
                  :class="item.assess == 2?'treadBox':'treadNoBox'"
                  @click="reviews(item,2)"
                >
                  <p :class="item.assess == 2?'treadImg':'treadNoImg'"></p>
                  <p :class="item.assess == 2?'treadText':'treadNoText'">踩</p>
                </div>
              </div>
              <p v-if="item.content.answer.indexOf('本次核保到此结束')>-1">{{item.content.answer}}</p>
            </div>

            <div v-if="item.content.hebao_answer">
              <div
                v-for="(msg ,btn) in item.content.hebao_answer"
                :key="btn"
                v-text="btn"
                class="underwriting"
                @click="sendText(btn, {'hebao_answer':msg})"
              ></div>
            </div>
            <div v-if="!item.content.type && item.speaker==0&&item.content.recommendProducts.length>0">
              <!-- 根据你的情况，匹配到 <span></span> 款产品
              <img class="underwriting_result" :src="require('@/assets/images/核保结果@2x.png')" /> -->
              <div
                class="underwriting"
                @click="changePage('/recommendProduct/'+item.sentence_id)"
              >查看结果</div>
            </div>
          </div>
        </dd>
      </dl>
      <!-- <tip-bar
        :title="item.content=='核保' ? '已切换至智能核保模式':(item.content=='退出核保'?'已切换至普通对话模式':'')"
        :showType="item.content=='核保'?'start':(item.content=='退出核保'?'end':'')"
        v-if="hideHistoryText!=1 && item.content=='核保' || hideHistoryText!=1 && item.content=='退出核保'"
      ></tip-bar> -->
    </div>
    <van-overlay
      :show="showCode"
      @click="showCode = false"
    >
      <div
        class="wrapper"
        @click.stop
      >
        <div class="codeBox">
          <img
            class="codeImg"
            src="../../../assets/images/QrCodeImg.jpeg"
            alt=""
          >
        </div>
      </div>
    </van-overlay>
    <!-- 展示pdf -->
    <van-icon v-show="showPdfModel" name="clear" @click="hidePdf" class="hide-pdf" size="30"/>
    <van-popup v-model="showPdfModel" :lock-scroll="scroll" close-icon="close" class="pdf-container">
      <pdf
        ref="pdf"
        v-for="i in numPages" 
        :key="i.toString()"  
        :src="url" 
        :page="i"
      ></pdf>
    </van-popup>
    <!-- 人工服务-填写手机号 -->
    <van-dialog v-model="artificial_dialog" title="填写手机号获取人工服务" show-cancel-button @confirm="artificialHandle">
      <input class="call_mobile" type="number" v-model="call_mobile" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="手机号码"/>
    </van-dialog>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { appraise, getQuestion, todayRemind, postMobileToArtificial } from "@/fetch/api/chat.js";
import { mapState, mapMutations } from "vuex";
import { GetPhoneSystem } from '@/utils/tools'
import Config from '@/fetch/config'
// import TipBar from "./TipBar.vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    chat_model: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      text: "",
      underwriting: this.$route.query.underwriting,
      hideHistoryText: this.$route.query.hideHistoryText,
      welcome_msg: "",
      showCode: false,
      recommendList: [],
      url:"",
      numPages: null,
      showPdfModel: false,
      scroll:false,
      showJybInfo: false,
      call_mobile:"",
      artificial_dialog:false,
      current_dialog:{}
    };
  },
  directives: {
    numberOnly: {
      bind: function (el) {
        el.handler = function () {
          el.value = el.value.replace(/\D+/, "");
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function (el) {
        el.removeEventListener("input", el.handler);
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    replaceLabel: function (val) {
      if (val && val.indexOf("<br>") > -1) {
        return val.replace(/<br>/g, "\n");
      } else {
        return val;
      }
    },
    format2MMYY: function(val) {
      if (val) {
        const date = new Date(val.replace(/-/g, '/'))
        const day = date.getDate()
        return date.getMonth() + 1 + '/' + (day < 10 ? '0' : '') + day
      }
    },
    format2mmss: function(val) {
      if (val) {
        return val.substring(0, 5)
      }
    }
  },
  components: {
    pdf,
  },
  created() {
    if (this.$route.query.from === 'app') {
      this.showJybInfo = true
      this.fetchJYBInfo()
    } else {
      this.showJybInfo = false
      this.getQuestion()
    }
  },
  methods: {
    ...mapMutations(["SET_CHAT_LIST"]),
    inputChange() {
      this.age = this.age.replace(/[^\d]/g, "");
    },
    async getQuestion() {
      const response = await getQuestion();
      if (response.code == 200) {
        this.recommendList = response.questions;
      } else {
        this.$toast("请求失败");
      }
    },
    async fetchJYBInfo() {
      const res = await todayRemind({ jyb_id: localStorage.getItem('jyb_id') })
      console.log(res)
      if (res.code === 200) {
        this.recommendList = res.data.remind.filter(it => it.count > 0)
      } else {
        this.$toast("请求失败")
      }
    },
    addChatToKnowdge(index) {
      let list = this.list;
      this.SET_CHAT_LIST({ type: "assignment", data: list });
      let collectList = [list[index - 1], list[index]];
      this.$router.push(`/knowledge-base/edit/0?type=collect`);
      sessionStorage.setItem("collectList", JSON.stringify(collectList));
    },
    goLook(item) {
      this.$router.push(`/knowledge-base/detail/${item.online_data_id}`);
    },
    // 点击发送消息
    sendMessage(each, num) {
      if (num == 1) {
        this.$emit("sendText", each.content, {});
        return;
      }
      if (num === 2) {
        return this.$emit("sendText", each.title, {});
      }
      if (each.canClick) {
        if (each.text.indexOf("抱歉，Bebot暂时还无法理解您的问题") > -1) {
          this.showCode = true;
          return;
        }
        this.$emit("sendText", each.text, {});
      }
    },
    sendText(item, option = {}) {
      if (option.reset_disease == 1 || option.reset_disease == -1) {
        if (option.reset_disease == -1) {
          this.flag = false;
          this.lastText = false;
        }
      }
      this.$emit("sendText", item, option);
    },
    changePage(path) {
      this.$router.push(path);
    },
    reviews(item, val) {
      appraise(item.sentence_id, { agree_oppose: val }).then((res) => {
        if (res.code == 200) {
          this.SET_CHAT_LIST({
            type: "evaluate",
            data: Object.assign(item, {
              agree_oppose: res.result.agree_oppose,
            }),
          });
        } else if (res.code == 400) {
          this.$toast(`${res.error}`);
        }
      });
    },
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        console.error('pdf 加载失败', err);
      })
    },
    showPdf(url){
      this.url=url
      this.getNumPages()
      this.showPdfModel=true
    },
    hidePdf(){
      this.showPdfModel=false
    },
    // 跳转文章
    goArticle() {
      window.open(`${Config.jybDomain}/web/article-home?userId=${localStorage.getItem('jyb_id')}&token=${localStorage.getItem('app_token')}`)
    },
    goCalendar() {
      window.open(`${Config.jybDomain}/web/calendar-invitation?userId=${localStorage.getItem('jyb_id')}&token=${localStorage.getItem('app_token')}`)
    },
    goPosterShare() {
      try {
        if (GetPhoneSystem() == 'isAndroid') {
          window.mobile_obj.sharePoster()
        } else if (GetPhoneSystem() == 'isIOS') {
          window.webkit.messageHandlers.sharePoster.postMessage({
            body: {}
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    //验证手机号
    vc_mobile(){
        let reg = new RegExp('^1[3-9]\\d{9}$')
        return reg.test(this.call_mobile)
    },
    //打开人工协助弹窗
    openArtificialDialog(dialog){
      this.current_dialog = dialog
      this.artificial_dialog=true
    },
    //提交需要人工协助的手机号码
    artificialHandle(){
      if(!this.vc_mobile()){
          this.$toast.fail('手机号码格式不正确');
          return
      }
      postMobileToArtificial({"mobile": this.call_mobile, "answer_id": this.current_dialog.sentence_id})
      .then((res)=>{
        if (res.code==200){
          this.$toast('提交成功，后期会有服务人员联系您！');
          this.artificial_dialog = false
          this.current_dialog.content['linkman'] = this.call_mobile
        }else{
          this.$message.warning('网络异常，请稍后重试！');
        }
      })
    }
  },
  activated() {
    this.$nextTick(() => {
      let container = document.getElementById("chatView");
      if (container && container.scrollHeight != null) {
        container.scrollTop = container.scrollHeight;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.history-chat-box {
  .header-nav {
    .van-nav-bar__title {
      color: #fff;
      font-size: 0.43rem;
    }
  }
  .content {
    word-break: break-all;
    white-space: normal;
    position: relative;
  }
}
.recommend {
  .list {
    padding-top: 0.3rem;
  }
  .list:first-child {
    padding-top: 0px;
  }
  .popularText {
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    overflow-wrap: break-word;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .cutover {
    text-align: right;
    margin: 0.5rem 0.2rem 0 0;
  }
}
.contentText {
  position: relative;
  text-align: left;
  min-width: 5.33rem;
  overflow-wrap: break-word;
}
.lines {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.assess {
  display: flex;
  justify-content: flex-end;
  .greatNoBox,
  .treadNoBox {
    background: none !important;
    border-radius: 0.05rem 0.53rem 0.53rem 0.53rem !important;
    border: 0.03rem solid rgba(255, 255, 255, 0.25) !important;
  }
  .greatBox,
  .greatNoBox,
  .treadNoBox,
  .treadBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.39rem;
    height: 0.64rem;
    background: #ffffff;
    border-radius: 0.41rem;
    margin-top: 0.3rem;
    .greatNoImg {
      width: 0.42rem;
      height: 0.42rem;
      background: url("../../../assets/images/greatNoImg.png") no-repeat;
      background-size: 100% 100%;
    }
    .greatImg {
      width: 0.42rem;
      height: 0.42rem;
      background: url("../../../assets/images/greatImg.png") no-repeat;
      background-size: 100% 100%;
    }
    .treadImg {
      width: 0.42rem;
      height: 0.42rem;
      background: url("../../../assets/images/treadImg.png") no-repeat;
      background-size: 100% 100%;
    }
    .treadNoImg {
      width: 0.42rem;
      height: 0.42rem;
      background: url("../../../assets/images/treadNoImg.png") no-repeat;
      background-size: 100% 100%;
    }
    .greatNoText,
    .treadNoText,
    .greatText,
    .treadText {
      font-size: 0.35rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2150c8;
      margin-left: 0.13rem;
      opacity: 0.8;
    }
    .greatNoText,
    .treadNoText {
      color: #fff;
      opacity: 1;
    }
  }
  .treadBox,
  .treadNoBox {
    margin: 0.3rem 0.37rem 0rem 0.27rem;
  }
}

.noChatText {
  opacity: 0.9;
  padding-bottom: 0.24rem;
  white-space: pre-line;
}
.chatText {
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #ffffff;
  font-weight: 700;
  padding-bottom: 0.16rem;
}

.other {
  width: 2.21rem;
  height: 0.64rem;
  border-radius: 0.32rem;
  opacity: 0.7;
  border: 0.03rem solid #ffffff;
  margin: 0 auto;
  text-align: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.underwritingText,
.manyUnderwritingText {
  width: 2.43rem;
  height: 0.83rem;
  position: absolute;
  left: 50%;
  bottom: -25%;
  transform: translate(-50%);
  background: url("../../../assets/images/underwritingImg.png") no-repeat;
  background-size: 100% 100%;
}
.manyUnderwritingText {
  position: absolute;
  left: 50%;
  bottom: -14%;
  transform: translate(-50%);
  background: url("../../../assets/images/underwritingImg.png") no-repeat;
  background-size: 100% 100%;
}
.underwriting {
  min-width: 2.346667rem;
  min-height: 0.8rem;
  background: linear-gradient(180deg, #a566ff 0%, #6645ff 100%);
  border-radius: 0.213333rem;
  font-size: 0.346667rem;
  line-height: 0.8rem;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  margin: 0.133333rem;
}
.underwriting_result {
  background-image: url("/src/assets/images/核保结果@2x.png");
  background-size: 4.933333rem 5.706667rem;
  width: 4.933333rem;
  height: 5.706667rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.codeBox {
  width: 200px;
  height: 200px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.codeImg {
  width: 180px;
  height: 180px;
  position: relative;
  z-index: 100;
  -webkit-user-select: none;
}
.codeImg:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-modify: read-write-plaintext-only;
}
.pdf-container{width: 102%; height: 80%; overflow: auto;}
.pdf-text{border-bottom: 1px solid rgba(255, 255, 255, 1);font-weight: 600;}
.hide-pdf{position: fixed;top: 11%; right: 1%; z-index: 3000;}
.chat-content .content.remind-content {
  padding: 0;
}
.remind-row {
  display: flex;
  height: 36px;
  // font-size: 16px;
  align-items: center;
  padding: 0 .32rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  .text {}
  .count {
    width: 16px;
    height: 16px;
    background-color: #F15654;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
  }
  .arrow {
    margin-left: auto;
    color: #fff;
  }
  &:active {
    opacity: .7;
  }
  &:last-child {
    border-bottom: none;
  }
}
.info-row {
  display: flex;
  height: 36px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  .article-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.article-head {
  height: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.go-article__btn {
  margin-top: 12px;
  padding: 4px 12px;
  display: inline-block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  border-radius: 16px;
  opacity: 0.8;
  color: #2150C8;
  &:active {
    opacity: .7;
  }
}
.to-do__wrap {
  display: flex;
  // align-items: center;
  flex-direction: column;
  i {
    margin-left: 32px;
    background-color: #fff;
    color: #4970D2;
    border-radius: 50%;
    padding: 2px;
  }
  i:active,.cld-btn {
    opacity: .7;
  }
  .cld-btn {
    width: 88px;
    text-align: center;
    padding: 4px;
    margin-top: 8px;
    background: #FFFFFF;
    border-radius: 16px;
    opacity: 0.8;
    color: #2150C8;
  }
}

.to-do__head {
  height: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.to-do__list:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.to-do__list {
  position: relative;
  display: flex;
  min-width: 5rem;
  height: 36px;
  align-items: center;
  margin-bottom: 18px;
  .visit-time {
    margin-left: 8px;
  }
  .visit-type {
    margin-left: 16px;
  }
  .user-name {
    margin-left: auto;
  }
  // &::before {
  //   content: '·';
  //   position: absolute;
  // }
  .point {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #AEBAF4;
    // border: 4px solid red;
  }
  .point-list {
    position: absolute;
    left: 0;
    bottom: -22px;
    width: 14px;
    padding: 0 4px;
    span {
      width: 4px;
      height: 4px;
      margin-bottom: 4px;
      background: #fff;
      border-radius: 50%;
      box-sizing: content-box;
      display: block;
    }
  }
}
.btn-group {
  text-align: right;
  span[class$=-btn] {
    padding: 4px 12px;
    background-color: #fff;
    color: #2150C8;
    opacity: .8;
    margin-left: 12px;
    border-radius: 16px;
  }
  span[class$=-btn]:active {
    opacity: .7;
  }
}
.img-list {
  white-space: nowrap;
  overflow: auto;
}
.poster-img {
  width: 72px;
  height: 108px;
  margin-right: 12px;
}
.artificial {width: 2.1rem; font-size: 0.35rem; line-height: 0.4rem;}
.call_mobile{display: block; margin: 0.5rem auto; padding: 0 0.3rem; border-radius: 0.53rem;}
</style>
