//获取当前设备型号
export function GetPhoneSystem() {
  let u = navigator.userAgent,
    isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
    isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isAndroid) {
    return "isAndroid";
  } else if (isIOS) {
    return "isIOS";
  }
}
